
@use '/src/App.scss' as css;

.container-wrapper-no-search {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  position: relative;
  background-color:css.$theme_light_grey ;
  .noserach-spinner {
    position: absolute;
    top: 150px;
    height : 20px;
    width: 20px
  }
  img {
    position: absolute;
    top: 110px;
    width: 156.328px;
    height: 169px;
  }
  .no-ratings-text {
    font-family: "AvenirPro-regular";
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    //color: #000000cc;
    color:  css.$theme_text;
    position: absolute;
    top: 310px;

    //margin-top: 120px;
  }
}
