@use '/src/App.scss' as css;

.search-icon {
  
}

.search-icon-testimonials-search {
  margin-left: 5px;
  height: 18px;
  width: 18px;
}

.search-container {
  background: rgba(36, 36, 38, 1) !important;
  padding: 0 10px;
  border-radius: 5px;
  padding: 5px;
  padding-left: 15px;
  height: 42px;
}

.search-box.form-control {

  padding-right: 0 !important;
  height: 32px;
}
.search-container-main{
  margin-left: 5px;
  width: calc(100% - 62px);
  height: 42px;
}

.search-box:focus {
  outline: none !important;
  background-color: #e7e9ef !important;
  border-color: #e7e9ef !important;
  box-shadow: none !important;
}
.search-box.form-control {
  background-color: rgba(36, 36, 38, 1) !important;
  border: none;
  color: #fff !important;
  // font-family: WorkSans-Regular;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 5px;
  outline: none;
  text-align: left;
  border: 0px;

}


.search-box::placeholder {
  color: #8e8e93 !important;
  opacity: 1;
}

.form-control::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

form-control:focus {
  outline: none !important;
  background-color: none;
  border-color: none;
}

.image-loader{
  margin-left: 29px;
  margin-right: 12px; 
}

.testimonial-search-cancel-icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
}
