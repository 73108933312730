.skeleton_wrapper {
    background-color: #1E232D;
    height: 100vh;
    display: flex;
 position: relative;
 overflow: hidden;
}

.skeleton_sub_container {
    position: absolute;
    
    // top: 450px;
    bottom: 121px;

    
}
.circle_style {
    margin-left: 20px;
    // position: absolute;
    display: flex;
    justify-content: space-evenly;
    
}
.line1 {
    position: absolute;
    top: 1px;
    margin-left: 40px;
}
.line2 {
    position: absolute;
    top: 12px;
    margin-left: 40px;
}
.line3{
    position: absolute;
    top: -3px;
    /* right: 20px; */
    left: 300px;
  

}

.border_skel{
    border: 2px solid #242939;

}
.line_Wrapper {
    margin-top: 40px;
}

.big_line {
    margin-left: 20px;
    position: absolute;
}

.Big_line2 {
    position: absolute;
    margin-top: 27px;
    margin-left: 20px;
}
.Big_line3 {
    position: absolute;
    margin-top: 63px;
    display: block;
    padding-bottom: 10px;
    margin-left: 20px;
}

.headerLinvisible {
    position: absolute;
    margin-left: 13px;
    margin-top: 10px;
    background-color: rgba(255, 255, 255, 0.4);
}
.inline {
    position: absolute;
    top: 0;
}

.toper {
    display: flex;
    position: relative;
}
