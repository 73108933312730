.card-thumbnail {
  margin-top: 77px;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  .card-show {
    cursor: pointer;
  }

  .mini-card {
    width: 61px;
    height: 91px;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: center;
    position: relative;
    border-radius: 4px;
    border: 1px solid #c5c5c5;
    cursor: pointer;
    // background-position: center;
    box-shadow: 0px 4px 4px 0px #00000040;

    .profileImg {
      display: flex;
      justify-content: center;
      
      img {
        margin-top: 10px;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        object-fit: cover;
        background-color: #909090;
      }

      .profileImg-border-white {
        border: 0.1px solid #909090;
      }

      .profileImg-border-blue {
        border: 0.1px solid #3A90FF;
      }
    }

    .blue-tick-verified-icon {
      height: 5px;
      width: 5px;
      position: absolute;
      top: 20px;
      left: 33px;
    }
    .name {
      font-size: 4px;
      margin-top: 5px;
      // font-family: "Raleway";
      font-weight: 700;
      text-align: center;
      // letter-spacing: 0.5px;
      max-width: 51px;
      text-transform: lowercase;
      white-space: nowrap;
      overflow: hidden;
      margin: 0 auto;
      // padding: 0 4px;
      margin-top: 5px;

      text-transform: lowercase;
      &::first-letter {
        text-transform: capitalize !important;
      }
      white-space: nowrap;
      overflow: hidden;
      // width: 55px;
      // padding: 0 4px;
      // text-overflow: ellipsis;
    }
    .name2 {
      font-size: 3px;
      // font-family: "Raleway";

      font-weight: 600;
      text-align: center;
      // letter-spacing: 0.3px;
      white-space: nowrap;
      overflow: hidden;
      width: 50px;
      text-overflow: ellipsis;
      margin: 0 auto;
    }
    .designation {
      margin-top: 3px;
      // font-family: "Raleway";
      font-size: 2px;
      font-weight: 600;
      // letter-spacing: 0.3px;
      text-align: center;
    }
    .location {
      font-size: 2px;
      font-weight: 500;
      // font-family: "Raleway";
      // letter-spacing: 0.2px;
      text-align: center;
      margin-top: 1px;
    }

    .badges {
      img {
        width: 30px;
        margin-top: 4px;
      }
    }

    .bottomBar {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
      .wrapper {
        width: 5px;
        height: 5px;
        background: rgba(255, 255, 255, 0.2);
        margin-left: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 33%;
        img {
          width: auto;
          height: 4px;
        }

        .mini-card-thumb-superskill {
          width: 3px;
          height: 3px;
        }
      }
    }
  }
}

.bg-filter-mini-card-thumbnail {
  backdrop-filter: url(#filter);
  height: 100%;
}

.network_bottomBar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  .wrapper {
    width: 7px;
    height: 7px;
    background: rgba(255, 255, 255, 0.2);
    margin-left: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 33%;
    img {
      width: auto;
      height: 4px;
    }
  }
}

.no-mini-badges-icons {
  height: 9.5px;
}

.no-mini-badges-icons-network {
  height: 9.5px;
}