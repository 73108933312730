@use '/src/App.scss' as css;

.bottom-options {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  gap: 10px;

  .option-wrapper {
    width: 32px;
    height: 32px;
    border-radius: 10px;
    // background-color: rgb(117, 117, 117);
    background: #ffffff33;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img{
      height: 20px;
      width: auto;
      pointer-events: none;
    }

    .super-skill-icon {
      height: 16px !important;
      width: 12px !important; 
      // margin-top: -2px !important;
    }
  }
}

.mini-card-from-bottom {
  bottom: 65px;
}

.network-from-bottom {
  // bottom: 100px;
  bottom: 65px;
}

.custom-width{
  width: 100% !important;
}
