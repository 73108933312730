.grouped-network-list-shimmer-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: calc(100vh - 46px);
    overflow: hidden !important;

    .grouped-network-list-card-container-shimmer {
        position: relative;

        .network-list-network-span-shimmer {
            position: absolute;
            top: 3px;
            left: 29px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            gap: 8px;
            background-color: #0d0d0d;

            .network-list-network-span-name {
                margin-top: 6px;
            }
        }

        .grouped-network-list-card-shimmer {
            border: 1px solid #242939;
            border-radius: 8px;
            margin-top: 20px;
            padding: 45px 30px 30px 30px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            .grouped-network-list-card-title-subtitle {
                margin-top: 5px;
                display: flex;
                flex-direction: column;
                gap: 12px;
            }
        }
    }
}