@use "/src/App.scss" as css;

.network-card-container {
  display: flex;
  flex-direction: column;
  background-color: css.$theme_bg;
  width: 100%;
  max-width: 375px;
  z-index: 999 !important;
  position: absolute;
  top: 0;

  @media only screen and (min-height: 714px) {
    height: 100vh;
  }

  @media only screen and (max-width: 450px) {
    width: 100%;
    max-width: 100%;
  }

  .network-header_division {
    position: fixed;
    top: 0;
    z-index: 4;
    width: 100%;

    .network-check {
      display: flex;
      justify-content: space-between;
      height: 44px;
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      top: 0;
      background: css.$theme_bg;
      z-index: 4;
      width: 375px;

      @media screen and (max-width: 450px) {
        width: 100%;
      }

      .network-card-title {
        display: flex;
        color: css.$theme_text;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        width: calc(100% - 63px);

        .network-card-network-name {
          white-space: nowrap;
          overflow: hidden;
          max-width: calc(100% - 132px);
          text-overflow: ellipsis;
        }

        .network-card-title-text {
          color: css.$theme_text;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .network-close-button-div {
        text-align: right;
        cursor: pointer;

        img {
          pointer-events: none;
          border-radius: 50%;
        }
      }

      .network-close-button-loader {
        margin-bottom: 4px;
        margin-top: 3px;
        height: 20px;
        width: 20px;
        margin-left: 2px;
        margin-right: 2px;
      }
    }
  }

  .network-card-body-container {
    margin-top: 54px;

    .network-card-wrapper {
      // border: 1px solid #fff;
      color: #000;
      padding: 0 20px;
      position: relative;
      margin-bottom: 20px;

      .network-main-card {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border: 1px solid #444446;
        border-radius: 10px;
        color: #000;
        height: 638px;
        padding: 15px 8px;
        position: relative;

        .network-top-sharing {
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: relative;

          .network-share-icon {
            width: 20px;
            height: 20px;
            border-radius: 6px;
            background: rgba(255, 255, 255, 0.2196078431);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
              width: 12px;
              height: auto;
              pointer-events: none;
            }
          }

          .network-share-text {
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            cursor: pointer;
            padding-left: 5px;
          }
        }

        .dp-bg-white {
          background-color: #909090;
        }

        .network-profile-image {
          position: relative;
          display: flex;
          margin-left: auto;
          margin-right: auto;
          margin-top: 74px;
          margin-bottom: 14px;
          border-radius: 50%;
          width: 124px;
          height: 124px;

          img {
            margin: auto;
            width: 124px;
            height: 124px;
            border-radius: 50%;
            object-fit: cover;
            pointer-events: none;
            border: 1px solid #909090;
          }
        }

        .group-network-name-dp-container {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          margin-bottom: 6px;

          .group-network-dp {
            .group-network-dp-img {
              height: 30px;
              width: 30px;
              border: 1px solid #9e9e9e;
              border-radius: 50%;
            }
          }

          .group-network-title {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .network-name-div {
          height: 90px;

          .network-title-name {
            font-size: 30px;
            line-height: 36px;
            font-weight: 500;
            color: #ffffff;
            text-align: center;
            // white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            width: 100%;
            max-width: 350px;
            letter-spacing: 0.03em;
            margin: 0 auto;

            &::first-letter {
              text-transform: capitalize !important;
            }
          }
        }

        .network-address-container {
          text-align: center;
          margin-top: 12px;

          .network-address {
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.03em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            padding-left: 8px;
            padding-right: 5px;
          }
        }

        .card-branding {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          width: 92%;
          bottom: 0;

          .logo-elred {
            img {
              width: 70px;
              pointer-events: none;
            }
          }

          .main-card-profile {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            cursor: pointer;

            .profile-div {
              width: 18px;
              height: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 6px;
              background: #ffffff33;
              margin-right: 5px;
              cursor: pointer;

              img {
                pointer-events: none;
              }
            }

            .profile_txt {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
