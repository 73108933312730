@use '/src/App.scss' as css;

.details-wrapper {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(10, 75, 90, 0.7) 100%
  ) !important;
  // height: 100vh;
  border-radius: 0px !important;
}

.body_wrapper_test {
  height: calc(100vh - 44px);
  // padding: 0 20px 20px 20px;
  position: relative;
  background-position: center;
  background-size: cover;
  padding: 10px 24px 24px;
}

.quote-container-testimonial {
  img {
    width: 75px;
    height: auto;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease;
    clip-path: polygon(0% 19%, 100% 19%, 100% 100%, 0% 80%);
    margin-left: -13px;
    margin-top: -20px;
  }
  .date-container {
    .date-testimonial {
      font-size: 12px;
      font-weight: 500;
      margin-right: -10px;
      // font-family: "Poppins-regular";
    }
  }
}
.detail_overlay {
  position: relative;
  background-position: center;
  background-size: cover;
  background-color: #fff;
  height: calc(100vh - 44px);
}

.dp_position {
  margin-left: 10px;
  cursor: pointer;
}


.detail_text {
  margin-top: 58px;
  margin-bottom: 30px;
}

.profile-wrapper {
  padding: 0 !important;
  .user-profile {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
    pointer-events: none;
    border: 2px solid #c5c5c5;
  }

  .border-testimonial {
    border: 1.5px solid #909090;
  }

  .user-card {
    padding-left: 13px;
   // overflow: hidden;
   .user-name-testomonial {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
    width: fit-content;
  }

   .Testimonial-wrapper-bluetick{
    display: flex;
    .user {
      font-size: 20px;
    line-height: normal;
    overflow: hidden;
    letter-spacing: 0.5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
    width: 100%;
    font-weight: 600;
      // display: flex;
    }
   }
   
    .designation {
      font-size: 12px;
      font-weight: 400;
      // font-family: "Poppins-regular";
    }
  }
}

.b-ticks-testimonials {
  margin-left: 7px;
}
