@use "/src/App.scss" as css;

.header-without_Border {
  // padding: 20px 20px 20px 20px;
  display: flex;
  align-items: flex-end;
  background: red;
  position: sticky;
  top: 0;
  // background: #ffffff;
  background: css.$theme_bg;
  z-index: 1;
  .searchbar-main {
    position: absolute;
    max-width: 285px;
    width: 100% !important;
    height: 42px;
    border-radius: 4px;
    display: flex;
  //  background: rgb(234, 236, 242);
    background: css.$theme_dark_blue;
    top: 7px;
    left: 60px;
    border-width: 0px, 0px, 0px, 0px;
    border-style: none;
    align-items: center;
    border-color: rgb(35, 35, 35);
    display: flex;
    @supports (-webkit-touch-callout: none) {
      input {
        background: transparent;
        outline: none;
        width: 265px;
        border: none;
       // color: rgba(0, 0, 0, 1);
        color:css.$theme_text;
        //  font-family: "WorkSans-Regular";
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        // letter-spacing: 0em;
        text-align: left;
        margin-left: 5px;
        text-size-adjust: none;
        padding-top: 2px;
        margin-top: 5px;
      }
    }
    input {
      background: transparent;
      outline: none;
      width: 265px;
      border: none;
      // color: rgba(0, 0, 0, 1);
      color:css.$theme_text;
      //  font-family: "WorkSans-Regular";
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      // letter-spacing: 0em;
      text-align: left;
      margin-left: 5px;
      text-size-adjust: none;
      padding-top: 2px;
    }
    input::placeholder {
      // color: #b7b7b7;
      color:css.$theme_text;
      opacity: 0.5;
      // font-family: "WorkSans-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      max-width: 260px;
      white-space: nowrap;
      overflow: hidden;
      //  margin-top: 2px;
      text-overflow: ellipsis;
    }

    input:focus {
      text-size-adjust: none;
    }
  }

  .Search-img {
    // margin-top: 13px;
    margin-left: 12px;
    width: 18px;
    height: 18px;
  }
  .searchIconLoader {
    margin-left: 12px;
    // width: 18px;
    //height: 18px;
    margin-right: 3px;
    // margin-bottom: 1px;
    /* height: 15px !important; */
    //width: 18px !important;
  }
  .close-img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    // margin-top: 11px;
    cursor: pointer;
  }

  .view-back1 {
    cursor: pointer;
    margin-top: 5px;
    padding: 15px 23px 15px 29px;
   // padding: 11px 14px 15px 20px;
    z-index: 1;
  }
}
.view-all-header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
  padding: 0 !important;
  // border-bottom: 0.5px solid #00000029;

  .view-back1 {
    cursor: pointer;
     padding: 15px 23px 15px 29px;
    //padding: 15px 17px 15px 20px;
  }
  .back-icon-awar-spinner {
    margin-left: 29px;
    margin-right: 17px;
  }
  .title1 {
    // font-family: "WorkSans-Regular";

    // color: #000;
    color: css.$theme_text;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    //margin-left: 16px;
  }
  .search-icon {
    width: 38px;
    height: 38px;
    top: 3px;
    // left: 328px;
    //padding: 10px 0px 0px 0px;
    padding:10px;
    gap: 12px;
    border-radius: 14px;
   
    display: flex;
    position: absolute;
    right: 9px;
    background: #3C3C3C;
 
    .red-search-spinner{
      width: 18px;
      height: 18px;
    }

    img {
      cursor: pointer;
   
   
      width: 18px;
      height: 18px;
      //top: 13px;
    }
  }
}

@media (max-width: 420px) and (min-width:380px) {
  .searchbar-main {
    max-width: 300px !important;

    
  input {
  
    width: 285px !important;
  }
}
 
}