@use '/src/App.scss' as css;

.searchable-country-codes-main-container {
    display: inline-flex;
    position: relative;
    
    .searchable-country-codes-top-field {
        width: 72px;
        height: 46px;
        border-radius: 8px;
        background: rgba(36, 36, 38, 1);
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
        -webkit-user-select: none;
        padding: 0 12px;
    
        .codes-top-field-text {
            font-size: 16px;
            font-weight: 500;
            line-height: 19.2px;
            color: css.$theme_text;
        }

        .searchable-country-codes-down-icon {
            height: 20px;
            width: 20px;
            margin-top: -3px !important;
        }

        .searchable-country-codes-down-icon-rotate {
            transform: rotate(180deg);
        }
    }
    
    ::-webkit-scrollbar {
        width: 2px !important;
    }
    
    ::-webkit-scrollbar-thumb {
        background: rgba(217, 217, 217, 1) !important;
        border-radius: 1px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(217, 217, 217, 1) !important;
    }
    
    .searchable-country-codes-dropdown {
        position: absolute;
        top: 46px;
        left: 0;
        z-index: 2;
        width: 335px;
        max-height: 161px;
        border-radius: 0px 0px 4px 4px;
        background-color: rgba(36, 36, 38, 1);
        box-shadow: 0px 9px 12px 1px rgba(205, 205, 205, 0.1);
        padding: 6px 6px 6px 16px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border: 1px solid #909090 !important;

        @media only screen and (max-width: 450px) {
            width: calc(100vw - 41px);
        }
    
        .searchable-country-codes-search-container {
            display: flex;
            align-items: center;
            // height: 34px;
            padding-top: 3px;
            background-color: rgba(36, 36, 38, 1);
            width: calc(100% - 8px);
            border-bottom: 1px solid #909090 !important;
    
            .searchable-country-codes-search-icon-container {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: 24px;
                width: 24px;
                user-select: none;
                -webkit-user-select: none;
                // border-right: 0.8px solid rgba(35, 35, 35, 1);
    
                .searchable-country-codes-search-icon {
                    height: 18px;
                    width: 18px;
                    margin-top: -3px;
                    pointer-events: none;
                }

                .searchable-country-codes-search-icon-loader {
                    height: 16px;
                    width: 16px;
                    margin-top: -3px;
                    border-width: 1px;
                }
            }
    
            .searchable-country-codes-search-input {
                border: none;
                outline: none;
                font-size: 14px;
                font-weight: 500;
                line-height: 35px;
                letter-spacing: 1px;
                text-align: left;
                margin-left: 8px;
                width: calc(100% - 65px);
                height: 29px;
                color: css.$theme_text;
                background-color: rgba(36, 36, 38, 1) !important;
            }

            .searchable-country-codes-search-input::placeholder {
                color: #8E8E93 !important;
                font-size: 14px;
                font-weight: 500;
                line-height: 35px;
                letter-spacing: 1px;
                text-align: left;
            }
    
            .searchable-country-codes-search-input:focus {
                border: none;
                outline: none;
            }

            .searchable-country-codes-clear-icon {
                position: absolute;
                right: 20px;
                height: 16.8px;
                width: 16.8px;
                cursor: pointer;
                pointer-events: auto;
            }
        }
    
        .country-codes-list-divider-border {
            border-top: 1px solid #909090 !important;
            border-right: none !important;
            border-bottom: none !important;
            border-left: none !important;
            margin: 0 6px 0 0 !important;
            opacity: 1 !important;
        }
    
        .country-codes-list-container {
            max-height: 115px;
            overflow: auto;

            ::-webkit-scrollbar {
                width: 2px !important;
            }
            
            ::-webkit-scrollbar-thumb {
                background: rgba(217, 217, 217, 1) !important;
                border-radius: 1px;
            }
            
            ::-webkit-scrollbar-thumb:hover {
                background: rgba(217, 217, 217, 1) !important;
            }
        
            -ms-overflow-style: auto;  /* IE and Edge */
            scrollbar-width: auto;  /* Firefox */
    
            .country-codes-list-item-single {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 10px 0 10px 10px;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.8px;
                text-align: left;
                cursor: pointer;
                user-select: none;
                -webkit-user-select: none;
                margin-right: 6px;
                color: css.$theme_text !important;
        
                .country-codes-list-item-code-num {
                    width: 35px;
                    margin-right: 16px;
                    color: inherit !important;
                }
        
                .country-codes-list-item-flag {
                    height: 14px;
                    width: 24px;
                    pointer-events: none;
                    border-radius: 0;
                }

                .country-codes-list-item-flag-border {
                    border: 0.5px solid #d8d2d2;
                }
        
                .country-codes-list-item-name {
                    margin-left: 11px;
                    color: inherit !important;
                }
            }
        
            .country-codes-list-item-single:hover {
                background-color: rgba(204, 204, 204, 0.1);
                color: rgba(229, 229, 229, 1) !important; 
                // border-radius: 16px;
            } 
        
            // .country-codes-list-item-single-active,
            // .country-codes-list-item-single-active:hover {
            //      background-color: rgb(5, 131, 173);
            //     color: #fff; 
            // }

            .country-codes-list-item-single-active {
                background: rgba(204, 204, 204, 0.1);
                // border-radius: 16px;
                color: rgba(229, 229, 229, 1) !important; 
            }

            .country-codes-list-no-result {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #8E8E93;
                font-size: 14px;
                font-weight: 500;
                line-height: 35px;
                height: 114px;
                letter-spacing: 1px;
            }
        }
    }
}


//testing..