@use '/src/App.scss' as css;

.profile-picture-view {
    // background-color: rgb(255, 255, 255);
    background: css.$theme_bg;
    color: #000;
    height: 100vh;
    position: relative;
  
    .profile-pic-header {
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      background: css.$theme_bg;
  
      .profile-pic-back-spinner {
        margin: 14px 20px 14px 29px;
      }

      .profile-pic-back {
        img {
          cursor: pointer;
          padding: 15px 23px 15px 29px;
        }
      }
  
      .profile-pic-title {
        color: css.$theme_text;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 290px;
      }

      .profile-pic-header-skeleton {
        margin-left: -4px;
      }

      .network-profile-pic-title {
        color: css.$theme_text;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 273px;

        @media only screen and (max-width: 400px) {
          max-width: 245px;
        }
      }

      .network-profile-pic-header-skeleton {
        margin-left: -4px;
        width: 253px;
        margin-right: 20px;

        @media only screen and (max-width: 400px) {
          width: 230px;
        }
      }
    }

    .profile-picture-container {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90vh;

        img {
            max-width: 450px;
            width: 100%;
            height: auto;
            pointer-events: none;
            border-top: 1px solid #2C2C2E;
            border-bottom: 1px solid #2C2C2E;
        }
    }

    .default-profile-picture-container {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90vh;

      img {
          max-width: 450px;
          width: 100%;
          height: auto;
          pointer-events: none;
      }
    }

    .profile-pic-spinner {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}