@use '/src/App.scss' as css;

.resume-shimmer {
  // background-color: #E1E4EB;
  background-color: #2A313F;
  margin-top: 25px;
  // box-shadow: 0px 4px 4px 0px #c0c0c040;
  padding: 10px 24px;
  margin-bottom: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;

  #abt_shimmer {
    margin-left: 20px;
  }

  img {
    margin-right: 22px;
  }
}



.resume-shimmer1 {
  // background-color: #E1E4EB;
  background-color: rgba(225, 228, 235, 0.5607843137);
  /* margin-top: 5px; */
  box-shadow: 0px 4px 4px 0px rgba(192, 192, 192, 0.2509803922);
  padding: 10px 0px 10px 10px;
   margin-bottom: 2px;
  img {
    margin-right: 10px;
  }
}
.full-hr {
  margin: 0 !important;
  border: 0.5px solid !important;
}

.skills_shimmer {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-top: 10px;
  // margin-right: 10px;
}
.showing-img-loader {
  display: block !important;
}
.hiding-img-loader {
  display: none !important;
}
