.network-logo {
  width: 100px; /* Set width to 100 pixels */
  height: 100px; /* Set height to 100 pixels */
  object-fit: cover; /* Optional: This ensures the image covers the area without distortion */
}

ul {
  list-style-type: none; /* Removes the default bullets */
  padding: 0; /* Removes default padding */
}

li {
  margin: 10px 0; /* Optional: Adds some space between list items */
}

.group_main_name_div {
  position: relative;
  border-radius: 8px;
  border: 1px solid #8e8e93;
  min-height: 100px;
  // padding: 40px 10px;
  padding: 30px 0 20px 0;
  margin: 20px;
  margin-bottom: 35px;
  margin-top: 40px;

  .cluster-name {
    position: absolute;
    top: -15px;
    background: #0d0d0d;
    padding: 0px 10px;
    left: 29px;
    display: flex;
    align-items: center;
    .networkClusterName {
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 215px;

      @media only screen and (max-width: 450px) {
        max-width: calc(100vw - 160px);
      }
    }

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 8px;
      border: 0.5px solid #9e9e9e;
      background: white;
      object-fit: contain;
    }
  }
}

ul {
  padding: 0 30px !important;
  .li_items {
    display: flex;
    // gap: 12px;
    align-items: center;
    cursor: pointer;

    .network-list-card-single-img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid #909090;
    }

    .network_details_div {
      margin-left: 12px;
      .n_name {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      .n_location {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width:361px) and (max-width:380px) {
  .n_name{
    max-width: 210px !important;
  }
}
@media (min-width:380px) and (max-width:400px) {
  .n_name{
    max-width: 230px !important;
  }
}
@media (min-width:400px) and (max-width:420px) {
  .n_name{
    max-width: 250px !important;
  }
}
@media (min-width:420px) and (max-width:450px) {
  .n_name{
    max-width: 270px !important;
  }
}