@use '/src/App.scss' as css;

.container-company-titles-popup {
    .profile-company-titles-container {
        max-height: 50vh;
        overflow-y: hidden;
        z-index: 100000;
        position: fixed;
        width: 375px;
        bottom: 0;
        border-radius: 16px 16px 0 0;
        background-color: css.$theme_deletePopup;
        padding: 20px;
        visibility: hidden;

        .company-titles-popup-top-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;

            .company-titles-popup-top-text-left {
                margin-left: 4px;
                font-family: "AvenirNextLTPro-Medium", sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                color: css.$theme_text;
            }

            .company-titles-popup-top-close-img {
                cursor: pointer;
                border-radius: 20px;
                width: 24px;
                height: 24px;

                img {
                    pointer-events: none;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .company-titles-popup-content {
            display: flex;
            margin-bottom: 20px;

            .company-titles-popup-content-image {
                height: 100%;

                .company-titles-popup-briefcase-img {
                    height: 32px;
                    width: 32px;
                    margin-right: 16px;
                }
    
                img {
                    height: 32px;
                    width: 32px;
                    pointer-events: none;
                }
            }

            .company-titles-popup-company-titles-text {
                max-height: calc(50vh - 88px);
                overflow: auto;
                padding-right: 6px;

                &::-webkit-scrollbar {
                    width: 4px;
                }

                -ms-overflow-style: auto;  /* IE and Edge */
                scrollbar-width: auto;  /* Firefox */

                &::-webkit-scrollbar-thumb {
                    background: #D9D9D9;
                }

                .company-titles-popup-title {
                    color: css.$theme_text;
                    font-family: "AvenirNextLTPro-Medium", sans-serif;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 33px;
                    letter-spacing: 0em;
                    text-align: left;
                }

                .break-company-titles-popup-title {
                    word-break: break-all;
                }
            }
        }
    }

    .profile-company-titles-container-show {
        transform: none;
        visibility: visible;
        transition: all 0.3s ease-in-out;
    }

    .profile-company-titles-container-hide {
        transform: translateY(100%);
        visibility: hidden;
        transition: all 0.3s ease-in-out;
    }
}

.company-titles-popup-overlay {
    position: fixed;
    visibility: visible;
    width: 375px;
    height: 100%;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 80000;
    transition: all 0.3s ease-in-out;
}

.company-titles-popup-overlay-hidden {
    position: fixed;
    visibility: hidden;
    width: 375px;
    height: 100%;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0);
    z-index: -1;
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 450px) {
    .container-company-titles-popup {
        .profile-company-titles-container {
            max-height: 50vh;
            overflow: hidden;
            z-index: 100000;
            position: fixed;
            width: 100%;
            bottom: 0;
            border-radius: 16px 16px 0 0;
            background-color: css.$theme_deletePopup;
            visibility: hidden;
            padding: 20px;

            .company-titles-popup-top-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;
    
                .company-titles-popup-top-text-left {
                    margin-left: 4px;
                    font-family: "AvenirNextLTPro-Medium", sans-serif;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: css.$theme_text;
                }
    
                .company-titles-popup-top-close-img {
                    cursor: pointer;
                    border-radius: 20px;
                    width: 24px;
                    height: 24px;
    
                    img {
                        pointer-events: none;
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            .company-titles-popup-content {
                display: flex;
                margin-bottom: 20px;
    
                .company-titles-popup-content-image {
                    height: 100%;
    
                    .company-titles-popup-briefcase-img {
                        height: 32px;
                        width: 32px;
                        margin-right: 16px;
                    }
        
                    img {
                        height: 32px;
                        width: 32px;
                        pointer-events: none;
                    }
                }
    
                .company-titles-popup-company-titles-text {
                    max-height: calc(50vh - 88px);
                    overflow: auto;
                    padding-right: 6px;

                    &::-webkit-scrollbar {
                        width: 4px;
                    }

                    -ms-overflow-style: auto;  /* IE and Edge */
                    scrollbar-width: auto;  /* Firefox */

                    &::-webkit-scrollbar-thumb {
                        background: #D9D9D9;
                    }

                    .company-titles-popup-title {
                        color: css.$theme_text;
                        font-family: "AvenirNextLTPro-Medium", sans-serif;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 33px;
                        letter-spacing: 0em;
                        text-align: left;
                    }

                    .break-company-titles-popup-title {
                        word-break: break-all;
                    }
                }
            }
        }
    }

    .profile-company-titles-container-show {
        transform: none;
        visibility: visible !important;
        transition: all 0.3s ease-in-out;
    }

    .profile-company-titles-container-hide {
        transform: translateY(100%);
        visibility: hidden;
        transition: all 0.3s ease-in-out;
    }

    .company-titles-popup-overlay {
        position: fixed;
        visibility: visible;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 80000;
        transition: all 0.3s ease-in-out;
    }
    
    .company-titles-popup-overlay-hidden {
        position: fixed;
        visibility: hidden;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0);
        z-index: -1;
        transition: all 0.3s ease-in-out;
    }
}
