@use '/src/App.scss' as css;

.needsCard {
  color: #000;
  position: relative;
  height: 100vh;
  background-color: css.$theme_bg;
  .needsCard_header {
    display: flex;
    align-items: center;
    padding-bottom: 0;
    justify-content: space-between;
    height: 44px;

    .left {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        padding: 15px 20px 15px 29px;
      }

      .title {
        color: css.$theme_text;
        margin-left: 20px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        letter-spacing: -1.9%;
        
      }

      .needs-top-margin-loader {
        margin: 15px 19px 15px 29px;
      }
    
    }
    .right {
      margin-right: 20px;
      // background: #147bff;
      background: #147bff;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-radius: 30px;
      cursor: pointer;

      #spinner {
        margin-right: 7px;
      }
      img {
        margin-right: 7px;
        pointer-events: none;
      }
      .title {
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: -0.05px;
      }
    }
  }
  .card_wrapper {
    width: 90%;
    border: 1px solid #444446;
    border-radius: 12px;
   
    margin: 0 auto;
    margin-top: 10px;
    padding: 20px;
    background-size: cover !important;
    background-position: center !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #000 !important;

    .profile_top_wrapper-shimmer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      backdrop-filter: blur(0px);

      .profile_div {
        display: flex;
        // align-items: center;

        .skellBorder-chart-need {
          margin-top: -4px;
        }

        .avatar {
          display: inline-flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;

          img {
            width: 34px;
            height: 34px;
            flex-shrink: 0;
            border-radius: 34px;
            object-fit: cover;
            border: 1px solid rgba(255, 255, 255, 1);
            pointer-events: none;
            background-color: #fff;
          }
        }
        .name_detail {
          margin-left: 10px;
          .name {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: white;
            max-width: 145px;
            width: fit-content;
            line-height: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .name_withAdhar {
            max-width: 125px !important;
          }
          .bluetick-NeedsProfile {
            margin-left: 2px;
            width: 14px;
            height: 14px;
            img {
              width: 14px;
              height: 14px;
              position: absolute;
              pointer-events: none;
              margin-top: 2px;
              margin-left: 1px;
            }
          }
          .location {
            font-family: "AvenirNextLTPro-Regular";
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            color: white;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            max-width: 155px;
            margin-top: 2px;
            display: flex;
            .needs_designation {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // max-width: 130px;
              max-width: 50%;
              width: fit-content;
              padding-right: 3px;
            }
            
            .needs-designation-half{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // max-width: 130px;
              max-width: 80%;
              width: fit-content;
              padding-right: 3px;
            }
            .needs_designation-alone {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // max-width: 130px;
              //  max-width: 80%;
              width: fit-content;
              margin-right: 3px;
            }
            .needs_company {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // max-width: 130px;
              max-width: 50%;
              width: fit-content;
              margin-left: 3px;
            }
            .needs_company-alone {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // max-width: 130px;
              max-width: 80%;
              width: fit-content;
              margin-left: 3px;
            }
            .needs-company-more {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // max-width: 130px;
              max-width: 80%;
              width: fit-content;
              margin-left: 3px;
            }
            .needs-company-less {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // max-width: 130px;
              max-width: 70%;
              width: fit-content;
              margin-left: 3px;
            }
          
          
            .needs_designation,
            .needs_company {
              font-family: "AvenirNextLTPro-Regular";
            }
          }
        }
      }

      .view_button {
        // background-color: #147bff;
        // font-size: 10px;
        // font-weight: 500;
        // line-height: 12px;
        // color: white;
        // padding: 4px 12px;
        // border-radius: 20px;
        cursor: pointer;
        display: flex;
        padding: 4px 10px;
        width: 78px;
        height: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        background: #147bff;
        align-self: center;
        margin-left: 10px;
        color: #fff;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .profile_top_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      backdrop-filter: blur(0px);

      .profile_div {
        display: flex;
        // align-items: center;

        .avatar {
          display: inline-flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;

          img {
            width: 34px;
            height: 34px;
            flex-shrink: 0;
            border-radius: 34px;
            object-fit: cover;
            border: 1px solid rgba(255, 255, 255, 1);
            pointer-events: none;
            background-color: #fff;
          }
        }
        .name_detail {
          margin-left: 10px;
          .name {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: white;
            max-width: 145px;
            width: fit-content;
            line-height: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .name_withAdhar {
            max-width: 125px !important;
          }
          .bluetick-NeedsProfile {
            margin-left: 2px;
            width: 14px;
            height: 14px;
            img {
              width: 14px;
              height: 14px;
              position: absolute;
              pointer-events: none;
              margin-top: 2px;
              margin-left: 1px;
            }
          }
          .location {
            font-family: "AvenirNextLTPro-Regular";
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            color: white;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            max-width: 155px;
            margin-top: 2px;
            display: flex;
            .needs_designation {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // max-width: 130px;
              max-width: 50%;
              width: fit-content;
              padding-right: 3px;
              text-transform: capitalize;
            }
            .needs-designation-half{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // max-width: 130px;
              max-width: 80%;
              width: fit-content;
              padding-right: 3px;
            }
            .needs_designation-alone {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // max-width: 130px;
              // max-width: 80%;
              width: fit-content;
              margin-right: 3px;
              text-transform: capitalize;
            }
            .needs_company {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // max-width: 130px;
              max-width: 50%;
              width: fit-content;
              margin-left: 3px;
            }
            .needs-company-more {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // max-width: 130px;
              max-width: 80%;
              width: fit-content;
              margin-left: 3px;
            }
            .needs_company-alone {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // max-width: 130px;
              max-width: 80%;
              width: fit-content;
              margin-left: 3px;
            }
            .needs-company-less {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // max-width: 130px;
              max-width: 70%;
              width: fit-content;
              margin-left: 3px;
            }
            .needs_designation,
            .needs_company {
              font-family: "AvenirNextLTPro-Regular";
            }
          }
        }
      }

      .view_button {
        // background-color: #147bff;
        // font-size: 10px;
        // font-weight: 500;
        // line-height: 12px;
        // color: white;
        // padding: 4px 12px;
        // border-radius: 20px;
        cursor: pointer;
        display: flex;
        padding: 4px 10px;
        width: 78px;
        height: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        background: #147bff;
        align-self: center;
        margin-left: 10px;
        color: #fff;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .details_div {
      background: #ffffff38;
      backdrop-filter: blur(10px);
      width: 100%;
      height: 250px;
      border-radius: 10px;
      padding: 16px 12px 16px 16px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }

      -ms-overflow-style: auto; /* IE and Edge */
      scrollbar-width: auto; /* Firefox */

      &::-webkit-scrollbar-track-piece {
        background: transparent;
        margin-top: 7px;
        margin-bottom: 7px;
        margin-right: 2px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #ffffffb4;
        border-radius: 4px;
      }

      // scrollbar-width: thin !important;

      .inner_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        .date {
          font-family: "AvenirNextLTPro-Regular";
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -5%;
          background: rgba(255, 255, 255, 0.23);
          padding: 3px 10px;
          border-radius: 40px;
          padding-bottom: 2px;
        }
        .share {
          cursor: pointer;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -5%;
          padding: 2px 10px;
          border-radius: 40px;
          padding-bottom: 1px;
          margin-right: 5px;
          border: 1px solid rgba(255, 255, 255, 1);
          display: flex;
          align-items: center;
          font-family: "AvenirNextLTPro-Regular";

          .spinner {
            width: 12px;
            height: 12px;
            margin-right: 4px;
          }
          img {
            width: 12px;
            margin-top: -2px;
            margin-right: 4px;
            pointer-events: none;
          }
        }
      }

      .ttt {
        overflow-y: auto;
      }

      .content_outer {
        // overflow: auto;
        height: 210px;

        // &::-webkit-scrollbar {
        //   width: 3px;
        //   height: 10px;
        //   margin-left: 10px !important;
        //   background-color: white;
        // }

        &::-webkit-scrollbar {
          width: 3px;
        }

        -ms-overflow-style: auto; /* IE and Edge */
        scrollbar-width: auto; /* Firefox */

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: #ffffffb4;
          border-radius: 3px;
        }

        .needs_card_inner_content_title {
          font-family: "AvenirNextLTPro-Regular";
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          text-align: left;
          color: #fff;
          word-wrap: break-word;
          white-space: break-spaces;
        }

        .content {
          font-family: "AvenirNextLTPro-Regular";
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          word-wrap: break-word;
          padding-bottom: 16px;
          white-space: break-spaces;
          // white-space: break-spaces;

          a {
            font-style: italic;
            color: white;
          }
        }
      }
    }

    .line {
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
  }

  .chat_area {
    // padding-top: 445px;

    .chat_single {
      // display: flex;
      /* align-items: center; */
      // justify-content: end;
      display: flex;
      // justify-content: end;
      // align-items: end;
      // flex-direction: column;
      margin: 0;
    }
    .chat_single_received {
      display: flex;
      /* align-items: center; */
      // justify-content: start;
    }
  }

  .input_wrapper {
    background: css.$theme_bg;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    .input_comp {
      margin: 0px 20px;
      display: flex;
      align-items: center;
      // position: fixed;
      justify-content: space-between;
      background-color: #444446;
      // padding: 13px 18px;
      border-radius: 46px;
      // position: fixed;
      width: 90%;
      bottom: 0;

      .input_emoji {
        width: 95%;
        display: flex;
        align-items: center;
        padding-left: 18px;
        input {
          color: #000;
          width: 100%;
          outline: none;
          border: none;
          background-color: transparent;
          // caret-color: #929292;

          &::placeholder {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #929292;
          }
        }
        .shimmer-emoji-loader-right{
          margin-right: 13px;
        }
        textarea {
          color: #000;
          resize: none;
          height: 45px;
          width: 238px;
          //  margin-left: 10px;
          // width: 90%;
          outline: none;
          text-decoration: none;
          border: none;
          background-color: transparent;
          // overflow-x: scroll;
          overflow-y: auto;
          padding-top: 10px !important;
          padding-bottom: 10px;
          border: none;
          background: transparent;
          -webkit-appearance: none;
          -moz-apperarance: none;
          -ms-appearance: none;
          -o-appearance: none;
          appearance: none;
          outline: none;
          // padding: 0px;
          resize: none;
          //width: 100%;
          //overflow-x: hidden;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          -ms-box-shadow: none;
          -o-box-shadow: none;
          box-shadow: none;
          line-height: 24px;
          // margin-bottom: 5px;
          &::-webkit-scrollbar {
            width: 4px;
          }

          -ms-overflow-style: auto; /* IE and Edge */
          scrollbar-width: auto; /* Firefox */

          &::-webkit-scrollbar-track-piece {
            background: transparent;
            margin-top: 2px;
            margin-bottom: 2px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background: #929292;
            border-radius: 10px;
            min-height: 20px;
          }

          &::placeholder {
            font-size: 16px;
            font-weight: 500;
            font-family: "AvenirPro-medium";
            line-height: 24px;
            color: rgba(162, 162, 164, 1);
            //padding-top: 10px;
          }
          &::-moz-placeholder {  
 
            font-size: 16px;
            font-weight: 500;
            font-family: "AvenirPro-medium";
            line-height: 24px;
            color: rgba(255, 255, 255, 0.9) !important;
        } 
        }

        .withemoji {
          // width: 85%;
          width: 100%;

          // padding-right: 10px;
        }
        .withoutemoji {
          width: 100%;
          margin-left: 0px !important;
        }
      }

      .enable_btn {
        cursor: pointer;
      }
    }
  }
}

.fixed_top {
  // background: white;
  // position: fixed;
  // padding-bottom: 10px;
  // min-width: 360px;
  // width: 100%;

  // background: #ff8484;
  // background-color: white;
  position: absolute;
  padding-bottom: 10px;
  min-width: 360px;
  width: 100%;
  top: 0;
  right: 0;
  height: 445px;
}

.error_msg_signup_field {
  color: #e72d38;
  margin-top: -18px;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.error_msg_phone_field {
  color: #e72d38;
  margin-top: -18px;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-left: -85px;
}

.no-scroll_chat_div {
  height: calc(100vh - 510px);
  overflow-y: hidden !important;
  background-color: css.$theme_bg;
  position: absolute;
  top: 436px;
  width: 100%;
  right: 0;
  bottom: 65px;
}

.scroll_chat_div {
  height: calc(100vh - 540px);
  background-color: css.$theme_bg;
  position: absolute;
  top: 436px;
  width: 100%;
  right: 0;
  bottom: 65px;
  overflow-y: auto;
}

.attachments_no-scroll_chat_div {
  height: calc(100vh - 556px);
  overflow-y: hidden !important;
  background-color: css.$theme_bg;
  position: absolute;
  top: 482px;
  width: 100%;
  right: 0;
  bottom: 65px;
}

.attachments_scroll_chat_div {
  height: calc(100vh - 586px);
  background-color: css.$theme_bg;
  position: absolute;
  top: 482px;
  width: 100%;
  right: 0;
  bottom: 65px;
  overflow-y: auto;
}

@media (min-width: 451px) {
  .fixed_top {
    max-width: 375px;
  }
  .needsCard {
    .input_comp {
      max-width: 335px;
    }
  }
}
.send_overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background: rgba(0, 0, 0, 0.3);
}
.z-index_set {
  background: rgba(0, 0, 0, 0.3);
  // position: relative;
  z-index: 5;
}
.over_lay {
  position: fixed;
  top: 0;
  z-index: 2;
  height: 100%;
  // max-width: 375px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 451px) {
  .send_overlay {
    max-width: 375px;
  }

  .over_lay {
    max-width: 375px;
  }
}

.input-Emoji-Container {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}
.smile-img {
  cursor: pointer;
  margin-right: 10px;
}
.close-img {
  cursor: pointer;
  fill: #e7e7e7;
  margin-right: 8px;
}

.dateHeader {
  // position: absolute;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.019em;
  text-align: center;
  color: rgba(101, 101, 101, 1);
  color: #ffffff;
}

.ScrollDate {
  width: auto;
  // height: 20px;
  position: fixed;
  border-radius: 20px;
  padding: 8px 15px 6px 15px;
  /* border: 1px solid gray; */
  text-align: center;
  background-color: rgba(0, 0, 0, 0.85);
  transform: translate(-50%, -50%);
  left: 50%;
  /* top: 1%; */
  margin-top: 15px;
  z-index: 5;
  color: rgba(222, 222, 222, 1) !important;
  // background-color: #fff;

  text-align: center;
  font-family: "AvenirNextLTPro-Light";

  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.send_btn_click,
.send_btn_click_disabled {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send_btn_click {
  cursor: pointer;
}

@media (min-width: 451px) {
  .input_wrapper {
    max-width: 375px;
    width: 100%;
  }
}

.needs-send-overlay-loader {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
