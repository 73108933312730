@use '/src/App.scss' as css;

.unsupported_browser {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  .content {

    .screen {
      max-width: 375px;
      width: 100%;
      background-color: white;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;

      #error_logo{
        margin-bottom: 40px;
      }

      .heading {
        color: #000;
        font-weight: 600;
        font-family: "AvenirNextLTPro-Medium";
        font-size: 18px;
        margin-bottom: 15px;
      }
      .desc_text {
        font-weight: 400;
        // font-family: "AvenirNextLTPro-Light";
        font-family: "AvenirNextLTPro-Regular";
        font-size: 14px;
        text-align: center;
        margin-bottom: 70px;
        color: #333333CC;
      
      }


      #chrome_logo{
        width: 48px;
        height: 48px;
      }
      .chrome {
        font-size: 16px;
        font-weight: 600;
        font-family: "AvenirNextLTPro-Regular";
        color: #666666;
        margin-top: 15px;
      }
    }
  }
}
