@use '/src/App.scss' as css;

.chi_cont {
  padding: 10px 0;
}
.main_conta {
  padding: 0px 16px;
}

.event_time {
  font-family: "AvenirPro-regular";
  font-size: 16px;
  font-weight: 500;
  // line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
.img_clock {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.event_title {
  font-family: "AvenirPro-medium";
  font-size: 36px;
  font-weight: 500;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin-top: 10px;
}

.desc_text {
  font-family: "AvenirPro-medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin-bottom: 10px;
  margin-top: 30px;
}

.desc {
  font-family: "AvenirPro-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: #000;
  text-align: left;
}
