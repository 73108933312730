@use '/src/App.scss' as css;

.Toastify__toast-theme--light {
    border-radius: 18px;
    padding: 9px 10px 9px 10px;
    background-color: #242426;
    --toastify-toast-min-height: 99px;
}

.Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    width: 345px;
}

@media only screen and (max-width: 450px) {
    .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
        bottom: 20px !important;
        left: 20px !important;
        width: calc(100% - 40px) !important;
        right: 20px !important;
        transform: translateX(0);
    }
}

@media only screen and (max-width: 480px) {
    .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
        bottom: 20px;
        width: 345px;
        left: calc((100% - 345px) / 2);
        right: calc((100% - 345px) / 2);
        transform: translateX(0);
    }
}

.private-contact-success-cross {
    margin-top: -24px;
    cursor: pointer;
}

.Toastify__toast {
    position: relative;
    height: 77px;
    align-items: center;
    justify-content: center;
}

.Toastify__close-button {
    opacity: 1;
}

.Toastify__close-button > svg {
    fill: #fff;
}

.private-toast-load {
    display: flex;
    margin-top: -6px;
}

.private-contact-cross-load {
    margin-right: 10px;
    position: absolute;
    right: 0;
    top: 15px;
}

.private-contact-toast-icon {
    height: 34px;
    width: 34px;
    margin-top: -12px;
}

.private-contact-toast-container {
    display: flex;

    .private-contact-toast-text-container {
        display: flex;
        margin-left: 20px;
        flex-direction: column;

        .private-contact-main-text {
            font-size: 16px;
            font-weight: 500;
            line-height: 19.2px;
            text-align: left;
            margin-bottom: 6px;
            color: #EE8D31 !important;
        }

        .private-contact-submitted-main-text-color { 
            color: #fff;
        }

        .private-contact-sub-text {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: #fff;
        }
    }
}
