.mybio {
  margin-top: 15px;
  // background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  // backdrop-filter: blur(10px);
  position: relative;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .next-button {
    // position: absolute;
    // top: 50%;
    // right: 5%;
    // transform: translate(-50%, -50%);
    margin-left: 9px;
    cursor: pointer;

    img {
      pointer-events: none;
    }
  }
}
