@use '/src/App.scss' as css;

.errorPage {
  .ele_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
    background-color: css.$theme_bg;
    .oops {
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
      color: #e72d38;
      text-align: center;
      margin-top: 20px;
    }

    .error_msg {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #6d6d6d;
      text-align: center;
      margin-top: 8px;
      width: 50%;
    }
  }
}
