@use '/src/App.scss' as css;

.cretificate-image-loader-icon{
    margin-top: 2px;
    margin-right: 10px;
}
.award-back {
    cursor: pointer;
    padding: 15px 23px 15px 29px;
  }

.back-icon-spinner-cert-img {
    margin: 12px 15px 15px 29px;
    cursor: default;
}