@use '/src/App.scss' as css;

@font-face {
  font-family: "WorkSans-Regular";
  src: url("../../assets/fonts/fonts/WorkSans-Regular.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "Raleway-Bold";
  src: url("../../assets/fonts/fonts/Raleway-Bold.ttf") format("opentype");
  /* You may include additional font properties if needed */
}
@font-face {
  font-family: "Raleway-Regular";
  src: url("../../assets/fonts/fonts/Raleway-Regular.ttf") format("opentype");
  /* You may include additional font properties if needed */
}

.popup-filter-bg {
  filter: brightness(40%);
  animation: add-filter 600ms;
}
@keyframes add-filter {
  from {
    filter: brightness(100%);
  }
  to {
    filter: brightness(40%);
  }
}

.test {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;

  height: 100vh;
  border: none !important;
  position: relative;

  .check {
    display: flex;
    justify-content: space-between;
    height: 44px;
    padding: 0 20px;
    /* margin: 20px 20px 0 20px; */
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 10px;
    position: sticky;
    top: 0;
    background: css.$theme_bg;
    z-index: 2;

    .user-title {
      color: css.$theme_text;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      // font-family: "WorkSans-Regular";

      white-space: nowrap;
      overflow: hidden;
      max-width: 320px;
      text-overflow: ellipsis;
    }
    .close-button-div {
      text-align: right;
      cursor: pointer;

      img {
        pointer-events: none;
        border-radius: 50%;
      }
    }
    .close-button-loader {
      margin-bottom: 5px;
      margin-top: 3px;
    }
  }

  .card-wrapper {
    color: #000;
    padding: 0 20px;
    position: relative;
    // border: 1px solid white;

    // .user-title-2{
    //   color: #000;
    // }

    .main-card {
      color: #000;
      /* height: 79.5vh; */
      padding: 15px 8px;
      border: 1px solid #444446;
      border-radius: 10px;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      height: 638px;

      .top-sharing {
        margin-right: 10px;
        display: flex;
        /* text-align: right; */
        align-items: center;
        justify-content: flex-end;
        position: relative;
        .share-icon {
          width: 20px;
          height: 20px;
          border-radius: 6px;
          background: rgba(255, 255, 255, 0.2196078431);
          display: flex;
          align-items: center;
          justify-content: center;
          // margin-right: 5px;
          cursor: pointer;

          img {
            width: 12px;
            height: auto;
            pointer-events: none;
          }
        }
        .title {
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
          cursor: pointer;
          padding-left: 5px;
        }
      }

      .profile-image {
        position: relative;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        margin-bottom: 25px;
        background-color: #909090;
        border-radius: 50%;
        width: 124px;
        height: 124px;

        img {
          margin: auto;
          width: 124px;
          height: 124px;
          border-radius: 50%;
          object-fit: cover;
          pointer-events: none;
        }

        .profile-image-border-white {
          border: 3px solid #909090;
        }

        .profile-image-border-blue {
          border: 3px solid #3A90FF;
        }
      }

      .name-div {
        position: relative;

        .title-name {
          font-size: 30px;
          line-height: 36px;
          font-weight: 700;
          color: #ffffff;
          text-align: center;
          letter-spacing: 1px;
          // font-family: "Raleway-Bold";

          white-space: nowrap;
          overflow: hidden;
          max-width: 100%;
          letter-spacing: -0.5px;
          text-overflow: ellipsis;
          padding: 0 0 0 8px;

          &::first-letter {
            text-transform: capitalize !important;
          }
        }
        .sub-name {
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
          margin-bottom: 30px;
          letter-spacing: 0.3px;
          // font-family: "Raleway-Regular";
          margin-top: 2px;
          white-space: nowrap;
          overflow: hidden;
          max-width: 100%;
          text-overflow: ellipsis;
          padding: 0 0 0 8px;
        }
      }

      .designations {
        text-align: center;
        position: relative;
        
        .title-designation {
          // font-family: "Raleway-Regular";
          font-weight: 500;
          letter-spacing: 0.5px;
          text-transform: capitalize;
        }
        .address {
          // font-family: "Raleway-Regular";
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.5px;
          margin-top: 5px;
        }
        margin-bottom: 40px;
      }

      .card-branding {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        width: 92%;
        bottom: 0;

        .logo-elred {
          img {
            width: 70px;
            pointer-events: none;
          }
        }

        .main-card-profile {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          cursor: pointer;
          .profile-div {
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background: #ffffff33;
            margin-right: 5px;
            cursor: pointer;

            img {
              pointer-events: none;
            }
          }

          .profile_txt {
            cursor: pointer;
          }
        }
      }
    }
  }
}

#canvas {
  background-color: white !important;
  width: 100%;
  overflow-y: auto;
}

.offcanvas-backdrop.show {
  // opacity: 0 !important;
}

.bottomPop {
  // border-radius: 16px 16px 0 0;
  border: none !important;
  // padding: 20px 25px;
  overflow-y: auto;

  height: 100vh !important;
  // to hide the scrollbar
  &::-webkit-scrollbar {
    width: 0px;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .skill-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px 20px 15px 20px;
    position: sticky;
    top: 0;
    height: 44px;
    background-color: #242426;
    z-index: 4;

    .title {
      color: css.$theme_text;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    .close-btn {
      cursor: pointer;
      border-radius: 50%;

      img{
        border-radius: 50%;
        height: 24px;
        width: 24px;
      }
    }
  }

  .skill-title-personality {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px 20px 15px 20px;
    position: sticky;
    top: 0;
    height: 44px;
    background-color: #0D0D0D;
    z-index: 4;

    .title {
      color: css.$theme_text;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    .close-btn {
      cursor: pointer;
      border-radius: 50%;

      img{
        border-radius: 50%;
        height: 24px;
        width: 24px;
      }
    }
  }

  .superskill-title {
    color: css.$theme_text;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 25px;
  }
}

.ratingsPop {
  border-radius: 16px 16px 0 0;
  border: none !important;
  // padding: 20px 25px;
  overflow-y: auto;

  height: 97vh !important;
  // to hide the scrollbar
  &::-webkit-scrollbar {
    width: 0px;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .skill-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    .title {
      color: black;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    .close-btn {
      cursor: pointer;
      border-radius: 50%;
    }

    img{
      border-radius: 50%;
    }
  }

  .superskill-title {
    color: #000;
    color: black;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 25px;
  }
}

.ratingsPopRatingsContainer {
  background-color: css.$theme_light_grey !important;
  border-radius: 16px 16px 0 0;
  border: none !important;
  // padding: 20px 25px;
  overflow-y: auto;

  height: 97vh !important;
  // to hide the scrollbar
  &::-webkit-scrollbar {
    width: 0px;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .skill-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    .title {
      color: black;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    .close-btn {
      cursor: pointer;
      border-radius: 50%;
    }

    img{
      border-radius: 50%;
    }
  }

  .superskill-title {
    color: #000;
    color: black;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 25px;
  }
}

.Ratings-Container-with-feedback {
  height: 100vh !important;
  border-radius: 0 !important;
}

@media only screen and (min-height: 940px) {
  .ratingsPopRatingsContainer {
    background-color: css.$theme_light_grey;
    border-radius: 16px 16px 0 0;
    border: none !important;
    // padding: 20px 25px;
    overflow-y: auto;
  
    height: 900px !important;
    // to hide the scrollbar
    &::-webkit-scrollbar {
      width: 0px;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  
    .skill-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;
      .title {
        color: black;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
  
      .close-btn {
        cursor: pointer;
        border-radius: 50%;
      }
  
      img{
        border-radius: 50%;
      }
    }
  
    .superskill-title {
      color: #000;
      color: black;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 25px;
    }
  }
}

.bottomPopSmall {
  border-radius: 16px 16px 0 0;
  border: none !important;
  padding: 20px 25px;
  // min-height: 200px;
  overflow-y: auto;
  height: auto !important;
  position: relative;

  background: rgba(0, 0, 0, 0) !important;
  height: 100vh !important;
  border-radius: 0;
  padding: 0;

  // to hide the scrollbar
  &::-webkit-scrollbar {
    width: 0px;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .skill-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    .title {
      color: css.$theme_text;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }

    .close-btn {
      cursor: pointer;
      border-radius: 50%;
    }

    img{
      border-radius: 50%;
    }
  }
}

.bottomPopSmallShareCard {
  border-radius: 16px 16px 0 0;
  border: none !important;
  padding: 20px 25px;
  // min-height: 200px;
  overflow-y: auto;
  height: 100vh !important;
  position: relative;

  // background: rgba(0, 0, 0, 0.3) !important;
  background: transparent !important;
  // height: 100vh !important;
  border-radius: 0;
  padding: 0;

  // to hide the scrollbar
  &::-webkit-scrollbar {
    width: 0px;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .skill-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    .title {
      color: css.$theme_text;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }

    .close-btn {
      cursor: pointer;
      border-radius: 50%;
    }

    img{
      border-radius: 50%;
    }
  }
}

.outer-div {
  background: #242426;
  padding: 20px 24px;
  margin: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 12px 12px 0 0;
}

.mid_part_skill {
  padding: 20px;
  padding-top: 0;
  background-color: #0D0D0D;
}

@media (min-width: 451px) {
  .bottomPop,
  .bottomPopSmall {
    max-width: 376px !important;
    width: 100%;
    margin: 0 auto;
    // background: rgba(0,0,0,0.3);
    // height: 100vh !important;
    // border-radius: 0;
    // padding: 0;

    // to hide the scrollbar
    &::-webkit-scrollbar {
      width: 0px;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .bottomPopSmallShareCard {
    bottom: 0 !important;
    height: 100vh !important;
    max-width: 376px !important;
    width: 100%;
    margin: 0 auto;
    // box-shadow: 0px -4px 13px 1px rgba(0, 0, 0, 0.1019607843);
  }

  #canvas {
    max-width: 376px;
    width: 100%;
    overflow-y:auto;
  }

  .ratingsPop {
    max-width: 376px !important;
    width: 100%;
    margin: 0 auto;
  }

  .ratingsPopRatingsContainer {
    background-color: css.$theme_light_grey;
    max-width: 376px !important;
    width: 100%;
    margin: 0 auto;
  }

  .outer-div {
    background: #242426;
    padding: 20px 24px;
    margin: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-radius: 12px 12px 0 0;

    max-width: 376px;
    left: 50%;
    transform: translate(-50%);
  }
}

.mini-badges {
  margin-top: 5px;
  .badges {
    position: relative !important;
    
    .badge {
      width: 4.5px;
      height: 4.5px;
      margin: 0 1px;
      padding: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      text-align: center;
     // background: transparent;
      img {
        width: 4.5px !important;
        height: 4.5px !important;
        border-radius: 50px !important;
        margin: 0 !important;
        background-color: #FFF;
      }
    }

    .total-badge {
      font-size: 2px !important ;
    }
  }
}
.loader-close {
  position: absolute;
  right: 0;
  margin-right: 20px;
}

.bg-filter-card-data-container {
  margin: -15px -8px;
  padding: 15px 8px;
  height: 100%;
  position: absolute;
  width: 100%;
  backdrop-filter: url(#filter);
}

.badges-height-space {
  height: 32px;
}

.network-badges-div-mini-card {
  margin-top: 31px;
}

.verified-user-card-icon-check-container {
  cursor: pointer;
  position: absolute;
  top: 157px;
  left: 194px;

  .verified-user-card-icon-check {
    height: 28px;
    width: 28px;
    pointer-events: none;
  }
}


@media only screen and (max-width: 450px) {
  .verified-user-card-icon-check-container {
    cursor: pointer;
    position: absolute;
    top: 158px;
    left: 57.5%;
    
    .verified-user-card-icon-check {
      height: 28px;
      width: 28px;
      pointer-events: none;
    }

    .verified-user-card-icon-check {
      height: 28px;
      width: 28px;
      pointer-events: none;
    }
  }
  .designations {
    margin-bottom: 30px;
  }
  .test .card-wrapper {
    color: #000;
    padding: 0 20px;
    position: relative;
    // border: 1px solid white;
    // overflow: auto;
    // height: 81vh;
  }
}
@media only screen and (max-width: 360px) {
  .test .card-wrapper .main-card .designations {
    margin-bottom: 20px;
  }
}

.email-address-wrapper{
  // margin-bottom: 36px;
}


.card-parent-div{
  background: css.$theme_bg;

  @media only screen and (min-height: 770px) {
    height: 100vh;
  }
}
