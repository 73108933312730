.designations_view {
  padding: 16px;
  border-radius: 10px;
  margin-top: 26px;
  max-height: 133px;
  overflow: hidden;

  .designation_title {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.35px;
    color: white;
  }

  .chip_collab_tag_shimmer_container {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 6px;

      .chip_collab_tag_shimmer {
        border-radius: 24px;
        width: 150px;

        @media only screen and (max-width: 450px) {
          width: 90px;
        }
    }
  }    

  .all_designations {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    max-height: 58px;
    overflow: hidden;

    .chip_designation {
      border-radius: 24px;
      border: 0.6px solid white;
      width: fit-content;
      min-width: 40px;
      // max-width: 132px;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.228px;
      padding: 2px 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // @media screen and (max-width: 374px) {
    //   .chip_designation {
    //     border-radius: 24px;
    //     border: 0.6px solid white;
    //     width: fit-content;
    //     min-width: 50px;
    //     // max-width: 113px;
    //     font-size: 12px;
    //     font-weight: 500;
    //     line-height: 18px;
    //     letter-spacing: -0.228px;
    //     padding: 2px 12px;
    //     white-space: nowrap;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //   }
    // }

    .chip_count {
      height: 24px;
      width: 39px;
      background-color: rgba(255, 255, 255, 0.4);
      padding: 0 12px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.228px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      cursor: pointer;
    }
  }
}
