@use '/src/App.scss' as css;

.container-wrapper-no-award {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  .img-no-awards{
    height: 169px;
    width: 156px;
  }
}

.no-ratings {
  font-family: "AvenirPro-regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  //color: #000000cc;
  color: css.$theme_text;
}

.no-Awards {
  width: 266px;
  height: 212px;
}
.search-finder{
  width: 285px;
  margin-right: 61px;
  height: 285px;
  margin-left: 29px;
}