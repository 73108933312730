.need_attachment{
     height: 100vh;

    .needattachment_header{
        position: sticky;
        top: 0;
        z-index: 2;
        background: #000;
       .left{
        display: flex;
        align-items: center;

        .image_div{
            padding: 15px 20px 15px 29px;
        }
        .cp{
            cursor: pointer;
        }
       }
    }

    .need_attachment_div{
        height: calc(100% - 44px);
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            max-width: 94%;
            max-height: 94%;
            border: 1px solid rgba(223, 223, 223, 1);
            border-radius: 4px;
        }
    }
}